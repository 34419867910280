import axios from '@/utils/request'

/**
 * 获取上传token的函数
 * 该函数用于向服务器请求七牛云上传所需的token。
 * 
 * @returns {Promise} 返回一个Promise对象，成功时携带从服务器获取的七牛云上传token及其他相关信息。
 */
export function getQiniuTokenApi() {
  // 向指定URL请求七牛云上传token
  return axios({
    url: '/global-url/api/v1/common/qiniu/upload/token',
    method: 'get'
  })
}
<template>
  <div>
    <div>
      <a-button type="primary" class="gmr-10" @click="save">保存</a-button>
      <a-button @click="back">返回</a-button>
    </div>
    <div class="g-flex g-flex-column g-align-center">
      <img :src="qiniuDomain+infoForm.head_img" class="gh-110p gw-100p gmb-10">
      <a-upload :show-upload-list="false" :before-upload="beforeUSAUpload">
        <a-button type="primary">更换头像</a-button>
      </a-upload>
    </div>
    <div class="gpv-10 gb-ccc gtc gfw-b gmv-15">基本信息</div>
    <a-form-model layout="inline" :model="infoForm">
      <div class="gmb-20">
        <a-form-model-item label="性别">
          <a-select v-model="infoForm.sex" placeholder="请选择性别" allowClear style="width:220px;">
            <a-select-option :value="2">保密</a-select-option>
            <a-select-option :value="1">男</a-select-option>
            <a-select-option :value="0">女</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="出生日期">
          <a-input placeholder="请输入出生日期" v-model="infoForm.birthday" style="width: 220px" />
        </a-form-model-item>
        <a-form-model-item label="籍贯">
          <a-input placeholder="请输入籍贯" v-model="infoForm.jiguan" style="width: 220px" />
        </a-form-model-item>
      </div>
      <div class="gmb-20">
        <a-form-model-item label="民族">
          <a-input placeholder="请输入民族" v-model="infoForm.minzu" style="width: 220px" />
        </a-form-model-item>
        <a-form-model-item label="身份证号">
          <a-input placeholder="请输入身份证号" v-model="infoForm.id_card" style="width: 220px" />
        </a-form-model-item>
        <a-form-model-item label="入学年月">
          <a-input placeholder="请输入入学年月" v-model="infoForm.rxny" style="width: 220px" />
        </a-form-model-item>
      </div>
      <div class="gmb-20">
        <a-form-model-item label="政治面貌">
          <a-input placeholder="请输入政治面貌" v-model="infoForm.zzmm" style="width: 220px" />
        </a-form-model-item>
        <a-form-model-item label="通讯地址">
          <a-input placeholder="请输入通讯地址" v-model="infoForm.address" style="width: 220px" />
        </a-form-model-item>
      </div>
    </a-form-model>
    <div class="gpv-10 gb-ccc gtc gfw-b gmv-15">家庭主要成员</div>
    <a-table bordered :columns="column" :data-source="infoForm.family_list" :pagination="false">
      <div slot="name" slot-scope="t,r">
        <a-input v-model="r.name"></a-input>
      </div>
      <div slot="age" slot-scope="t,r">
        <a-input-number v-model="r.age" :min="0" />
      </div>
      <div slot="guanxi" slot-scope="t,r">
        <a-input v-model="r.guanxi"></a-input>
      </div>
      <div slot="zzmm" slot-scope="t,r">
        <a-input v-model="r.zzmm"></a-input>
      </div>
      <div slot="mobile" slot-scope="t,r">
        <a-input v-model="r.mobile"></a-input>
      </div>
      <div slot="address" slot-scope="t,r">
        <a-input v-model="r.address"></a-input>
      </div>
      <div slot="zhiwu" slot-scope="t,r">
        <a-input v-model="r.zhiwu"></a-input>
      </div>
    </a-table>
  </div>
</template>
<script>
import { uploadFile } from "@/utils/common";
import { getQiniuTokenApi } from "@/api/common";
import { updateUserInfo } from "@/api/login";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      qnData: {},
      infoForm: {
        family_list: [],
      },
      column: [
        {
          title: "姓名",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          align: "center",
        },
        {
          title: "年龄",
          dataIndex: "age",
          scopedSlots: { customRender: "age" },
          align: "center",
        },
        {
          title: "关系",
          dataIndex: "guanxi",
          scopedSlots: { customRender: "guanxi" },
          align: "center",
        },
        {
          title: "政治面貌",
          dataIndex: "zzmm",
          width: 300,
          scopedSlots: { customRender: "zzmm" },
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "mobile",
          width: 300,
          scopedSlots: { customRender: "mobile" },
          align: "center",
        },
        {
          title: "工作地址",
          dataIndex: "address",
          scopedSlots: { customRender: "address" },
          align: "center",
        },
        {
          title: "职务",
          dataIndex: "zhiwu",
          scopedSlots: { customRender: "zhiwu" },
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain"]),
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.infoForm = JSON.parse(JSON.stringify(this.userInfo));
    getQiniuTokenApi().then((data) => {
      if (data.code === 200) this.qnData = data.data;
    });
  },
  methods: {
    //更换头像
    beforeUSAUpload(file) {
      uploadFile(file, this.qnData.qiniu_token, (res) => {
        this.infoForm.head_img = res.key;
      });
    },
    back() {
      this.$router.back();
    },
    save() {
      updateUserInfo(this.infoForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.$store.dispatch("user/getUserInfo");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 80px;
  text-align: right;
}
</style>